html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  background: black;
  color: white;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.darken {
  filter: brightness(50%);
}

.gradient {
  background: rgb(174, 238, 193);
  background: radial-gradient(
    circle,
    rgba(174, 238, 193, 1) 0%,
    rgba(166, 202, 225, 1) 79%,
    rgba(215, 190, 238, 1) 100%
  );
}

/* https://stackoverflow.com/a/64047736 */
.game-stack {
  grid-area: game-stack;
}

.place-items-center {
  place-items: center !important;
  grid-template-areas: 'game-stack';
}

.z-index-0 {
  z-index: 0 !important;
}

/* https://stackoverflow.com/a/22196760 */
.vertical-center {
  min-height: 90vh;
  display: flex;
  align-items: center;
}

.rw-30 {
  width: 30rem !important;
}

.list-style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

.drop-in {
  animation: drop-in 3s;
}
@keyframes drop-in {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
